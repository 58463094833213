.scrollable_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.scrollable_modal_wrapper {
    position: relative;
    z-index: 1001;
}

.scrollable_modal_container {
    max-height: 600px;
    width: 800px;
    max-width: 1000px;
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.25);
}

@media (max-width: 600px) {
    .scrollable_modal_container {
        width: 90%;
        margin: 20px;
    }
}

.scrollable_modal_header {
    padding: 16px 32px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.scrollable_modal_title {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
    font-weight: 700;
    font-size: 1.125rem;
}

.scrollable_modal_body {
    padding: 24px 32px 51px;
    overflow-y: auto;
}

.scrollable_modal_rich_text>*+* {
    margin-top: 1em;
}

.scrollable_modal_rich_text>*+ :is(h1, h2, h3) {
    margin-top: 2em;
}

.scrollable_modal_rich_text> :is(h1, h2, h3)+* {
    margin-top: 0.75em;
}

.scrollable_modal_rich_text ul,
.scrollable_modal_rich_text ol {
    margin-left: 20px;
    list-style-position: inside;
}

.scrollable_modal_footer {
    padding: 20px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #ddd;
    gap: 12px;
    position: relative;
}

.scrollable_modal_footer:after {
    content: "";
    display: block;
    position: absolute;
    top: -51px;
    left: 24px;
    right: 24px;
    height: 50px;
    flex-shrink: 0;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.75), transparent);
    pointer-events: none;
}

.scrollable_modal_button {
    padding: 12px 20px;
    border-radius: 8px;
    background-color: transparent;
    border: 0;
    font-weight: 600;
    cursor: pointer;
    transition: 0.15s ease;
}

.scrollable_modal_button_ghost:hover,
.scrollable_modal_button_ghost:focus {
    background-color: #dfdad7;
}

.scrollable_modal_button_primary {
    background-color: #6366f1;
    color: #fff;
}

.scrollable_modal_button_primary:hover,
.scrollable_modal_button_primary:focus {
    background-color: #4f46e5;
}

.scrollable_modal_close_button {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.15s ease;
}

.scrollable_modal_close_button:hover,
.scrollable_modal_close_button:focus {
    background-color: #dfdad7;
}