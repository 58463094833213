.modal_wrapper {

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .card {
        min-width: 400px !important;
        max-width: 500px;
        height: fit-content;
        background: rgb(255, 255, 255);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 30px;
        position: relative;
        box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.068);
        max-height: 300px;
        overflow-y: auto;
        font-family: 'Poppins', sans-serif;
        /* Added Poppins font family */
    }

    .card-content {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .card-heading {
        font-size: 20px;
        font-weight: 700;
        color: rgb(27, 27, 27);
        margin-bottom: 25px;
        font-family: 'Poppins', sans-serif;
        /* Added Poppins font family */
    }

    .card-description {
        font-weight: 100;
        color: rgb(102, 102, 102);
        font-family: 'Poppins', sans-serif;
        /* Added Poppins font family */
    }

    .card-button-wrapper {
        width: 100%;
        display: flex;
        align-items: space-around;
        justify-content: space-around;
        gap: 10px;
    }

    .card-button {
        width: 25%;
        height: 35px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        /* Added Poppins font family */
    }

    .input-field-container {
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 10px;
        margin-top: 10px;
        /* Center the container */
    }

    .input-field {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .input-field:focus {
        border-color: #6366f1;
    }



    .primary {
        background-color: rgba(99, 102, 241, 255);
        color: white;
    }

    .primary:hover {
        background-color: rgba(79, 70, 229, 255);
    }

    .secondary {
        background-color: #ddd;
    }

    .secondary:hover {
        background-color: rgb(197, 197, 197);
    }

    .exit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .exit-button:hover svg {
        fill: black;
    }

    .exit-button svg {
        fill: rgb(175, 175, 175);
    }
}