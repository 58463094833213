.searchbar-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.searchbar-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 320px;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 5px;
}

.searchbar-input-group {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
    /* Allow input to shrink */
}

.searchbar-label {
    display: flex;
    align-items: center;
    padding: 0 5px;
    flex-shrink: 0;
}

.searchbar-icon {
    width: 20px;
    height: 20px;
    fill: #727272;
}

.searchbar-input {
    border: none;
    background: transparent;
    padding: 8px;
    font-size: 14px;
    width: 100%;
    min-width: 0;
    /* Allow input to shrink */
}

.searchbar-input:focus-visible {
    outline: none;
    -webkit-box-shadow: 0 0 0 2px #727272;
    box-shadow: 0 0 0 2px #72727267;
}

.searchbar-button-group {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.searchbar-button-separator {
    height: 20px;
    width: 1px;
    background-color: #ccc;
    margin: 0 5px;
}

.searchbar-filter-button,
.searchbar-sort-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.searchbar-filter-icon,
.searchbar-sort-icon {
    width: 24px;
    height: 24px;
}

/* Dot indicator for the Sort and Filter button here */

.dot-indicator {
    width: 8px;
    height: 8px;
    background-color: #5c60f5;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 5px;
}

@media (max-width: 480px) {
    .searchbar-input-container {
        max-width: none;
    }

    .searchbar-input {
        font-size: 12px;
        padding: 6px;
    }

    .searchbar-filter-icon,
    .searchbar-sort-icon {
        width: 20px;
        height: 20px;
    }

    .searchbar-button-separator {
        margin: 0 2px;
    }
}