:root {
    --primary-red: hsl(1, 90%, 64%);
    --neutral-dark-grayish-blue: hsl(219, 12%, 42%);
    --neutral-light-grayish-blue-1: hsl(211, 68%, 94%);
    --neutral-very-light-grayish-blue: hsl(210, 60%, 98%);
    --white: #fff;
}

.notification {
    background: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    margin: 10px 0px;
    margin-left: 3rem;
    margin-right: 3rem;
}

.notification:hover {
    background: var(--neutral-light-grayish-blue-1);
}

.unreaded {
    /* Add any specific styles for unread notifications here */
}

.notification .avatar {
    width: 8%;
}

.notification .avatar img {
    width: 100%;
}

.notification .text {
    width: 92%;
}

.text .text-top p {
    color: var(--neutral-dark-grayish-blue);
}

.text .text-top p .profil-name {
    font-weight: 800;
}

.text .text-top p b {
    color: var(--neutral-dark-grayish-blue);
    display: inline-block;
}

.text-bottom {
    color: rgba(94, 103, 120, .5);
    margin-top: 10px;
}