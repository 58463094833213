/* Popup Settings */
.hamburger-button-popup {
    --burger-line-width: 1.125em;
    --burger-line-height: 0.125em;
    --burger-offset: 0.625em;
    --burger-bg: rgba(0, 0, 0, 0.15);
    --burger-color: #333;
    --burger-line-border-radius: 0.1875em;
    --burger-diameter: 2.125em;
    --burger-btn-border-radius: calc(var(--burger-diameter) / 2);
    --burger-line-transition: 0.3s;
    --burger-transition: all 0.1s ease-in-out;
    --burger-hover-scale: 1.1;
    --burger-active-scale: 0.95;
    --burger-enable-outline-color: var(--burger-bg);
    --burger-enable-outline-width: 0.125em;
    --burger-enable-outline-offset: var(--burger-enable-outline-width);
    --nav-padding-x: 0.25em;
    --nav-padding-y: 0.625em;
    --nav-border-radius: 0.375em;
    --nav-border-color: #ccc;
    --nav-border-width: 0.0625em;
    --nav-shadow-color: rgba(0, 0, 0, 0.2);
    --nav-shadow-width: 0 1px 5px;
    --nav-bg: #eee;
    --nav-font-family: Menlo, Roboto Mono, monospace;
    --nav-default-scale: 0.8;
    --nav-active-scale: 1;
    --nav-position-left: 0;
    --nav-position-right: unset;
    --nav-title-size: 0.625em;
    --nav-title-color: #777;
    --nav-title-padding-x: 1rem;
    --nav-title-padding-y: 0.25em;
    --nav-button-padding-x: 1rem;
    --nav-button-padding-y: 0.375em;
    --nav-button-border-radius: 0.375em;
    --nav-button-font-size: 12px;
    --nav-button-hover-bg: #6366f1;
    --nav-button-hover-text-color: #fff;
    --nav-button-distance: 0.875em;
    --underline-border-width: 0.0625em;
    --underline-border-color: #ccc;
    --underline-margin-y: 0.3125em;
    --button-margin-top: 1rem;
}

/* Popup Container */
.hamburger-button-popup {
    display: inline-block;
    text-rendering: optimizeLegibility;
    position: relative;
}

.hamburger-button-popup input {
    display: none;
}

@media (min-width: 768px) {

    /* Adjust the breakpoint as needed */
    .hamburger-button-popup {
        display: none;
        /* Hide on larger screens */
    }
}

/* Burger Button */
.hamburger-button-burger {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background: var(--burger-bg);
    width: var(--burger-diameter);
    height: var(--burger-diameter);
    border-radius: var(--burger-btn-border-radius);
    border: none;
    cursor: pointer;
    overflow: hidden;
    transition: var(--burger-transition);
    outline: var(--burger-enable-outline-width) solid transparent;
    outline-offset: 0;
    margin-top: var(--button-margin-top);

}

.hamburger-button-burger span {
    height: var(--burger-line-height);
    width: var(--burger-line-width);
    background: var(--burger-color);
    border-radius: var(--burger-line-border-radius);
    position: absolute;
    transition: var(--burger-line-transition);
}

.hamburger-button-burger span:nth-child(1) {
    top: var(--burger-offset);
}

.hamburger-button-burger span:nth-child(2) {
    bottom: var(--burger-offset);
}

.hamburger-button-burger span:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
}

/* Popup Window */
.hamburger-button-popup-window {
    transform: scale(var(--nav-default-scale));
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: var(--nav-padding-y) var(--nav-padding-x);
    background: var(--nav-bg);
    font-family: var(--nav-font-family);
    color: var(--nav-text-color);
    border-radius: var(--nav-border-radius);
    box-shadow: var(--nav-shadow-width) var(--nav-shadow-color);
    border: var(--nav-border-width) solid var(--nav-border-color);
    top: calc(var(--burger-diameter) + var(--burger-enable-outline-width) + var(--burger-enable-outline-offset) + 2 * var(--button-margin-top) - var(--nav-padding-y));
    /* Aligns top right corner */
    right: 0;
    /* Aligns to the right */
    transition: var(--burger-transition);
}

.hamburger-button-popup-window legend {
    padding: var(--nav-title-padding-y) var(--nav-title-padding-x);
    margin: 0;
    color: var(--nav-title-color);
    font-size: var(--nav-title-size);
    text-transform: uppercase;
}

.hamburger-button-popup-window ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/* Popup Buttons */
.hamburger-button-popup-window ul button {
    outline: none;
    width: 100%;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: var(--burger-color);
    font-size: var(--nav-button-font-size);
    padding: var(--nav-button-padding-y) var(--nav-button-padding-x);
    white-space: nowrap;
    border-radius: var(--nav-button-border-radius);
    cursor: pointer;
    column-gap: var(--nav-button-distance);
}

/* Specific SVG Colors */
.hamburger-button-popup-window ul li:nth-child(1) svg,
.hamburger-button-popup-window ul li:nth-child(2) svg,
.hamburger-button-popup-window ul li:nth-child(3) svg {
    color: #6366f1;
}

.hamburger-button-popup-window ul li:nth-child(4) svg,
.hamburger-button-popup-window ul li:nth-child(5) svg {
    color: rgb(153, 153, 153);
}

.hamburger-button-popup-window ul li:nth-child(7) svg {
    color: red;
}

/* Horizontal Rule */
.hamburger-button-popup-window hr {
    margin: var(--underline-margin-y) 0;
    border: none;
    border-bottom: var(--underline-border-width) solid var(--underline-border-color);
}

/* Hover and Active States */
.hamburger-button-popup-window ul button:hover,
.hamburger-button-popup-window ul button:focus-visible,
.hamburger-button-popup-window ul button:hover svg,
.hamburger-button-popup-window ul button:focus-visible svg {
    color: var(--nav-button-hover-text-color);
    background: var(--nav-button-hover-bg);
}

.hamburger-button-burger:hover {
    transform: scale(var(--burger-hover-scale));
}

.hamburger-button-burger:active {
    transform: scale(var(--burger-active-scale));
}

.hamburger-button-burger:focus:not(:hover) {
    outline-color: var(--burger-enable-outline-color);
    outline-offset: var(--burger-enable-outline-offset);
}

/* Toggle States */
.hamburger-button-popup input:checked+.hamburger-button-burger span:nth-child(1) {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

.hamburger-button-popup input:checked+.hamburger-button-burger span:nth-child(2) {
    bottom: 50%;
    transform: translateY(50%) rotate(-45deg);
}

.hamburger-button-popup input:checked+.hamburger-button-burger span:nth-child(3) {
    transform: translateX(calc(var(--burger-diameter) * -1 - var(--burger-line-width)));
}

.hamburger-button-popup input:checked~.hamburger-button-popup-window {
    transform: scale(var(--nav-active-scale));
    visibility: visible;
    opacity: 1;
}