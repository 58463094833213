.terms-and-conditions-container {
    z-index: 1000;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s;
    backface-visibility: hidden;
}

.terms-and-conditions-heading {
    margin-top: 70px;
    margin-bottom: 30px;
    font-size: 32px;
}

.terms-and-conditions-welcome {
    font-weight: 500;
}

.terms-and-conditions-intro {
    margin: 15px;
}

.terms-and-conditions-section {
    width: 80%;
    margin: 15px 0;
}

.terms-and-conditions-number {
    font-size: 32px;
    padding-right: 5px;
}

.terms-and-conditions-title {
    font-size: 32px;
}

.terms-and-conditions-paragraph {
    margin: 15px 0;
}

.terms-and-conditions-infoContainer {
    position: relative;
    width: 100%;
}

.terms-and-conditions-infoHeading {
    font-weight: 700;
    margin: 15px 15px 5px;
}

.terms-and-conditions-infoDetails {
    margin-left: 15px;
    margin-bottom: 15px;
}

.terms-and-conditions-infoDetails ul {
    margin-left: 20px;
    padding-left: 15px;
    list-style-type: disc;
    color: #333;
    /* Adjust this to complement the overall design's color scheme */
    font-size: 14px;
    /* Match the font size with the rest of the text */
}

.terms-and-conditions-infoDetails li {
    margin-bottom: 10px;
    /* Add spacing between list items */
    line-height: 1.6;
    /* Ensure readability */
}

.terms-and-conditions-line {
    height: 40px;
    width: 5px;
    position: absolute;
    top: 7px;
}

.terms-and-conditions-lineBlue {
    background-image: linear-gradient(to right bottom, #2998ff, #5643fa);
}

.terms-and-conditions-blue {
    color: #6366f1;
}

.terms-and-conditions-fadeIn {
    animation-name: terms-and-conditions-fadeIn;
    animation-duration: 0.8s;
    animation-timing-function: ease-out;
    z-index: 1001;
}

.invisible-bullet {
    list-style-type: none;
}

@keyframes terms-and-conditions-fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}