.ai_gradient_button_container {
    border: none;
    color: #ffffff;
    background-image: linear-gradient(30deg, #6366f1, #4f46e5, #7275f2);
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 17px;
    padding: 0.6em 1.5em;
    cursor: pointer;
    transition: background-position 0.5s ease, background-size 0.5s ease;
    box-shadow: 0 4px 6px rgba(79, 70, 229, 0.3), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ai_gradient_button_container:hover {
    background-position: right center;
    background-size: 200% auto;
    animation: ai_button_pulse 1.5s infinite;
    box-shadow: 0 6px 8px rgba(79, 70, 229, 0.4), 0 2px 4px rgba(0, 0, 0, 0.2);
}

@keyframes ai_button_pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(114, 117, 242, 0.4);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(114, 117, 242, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(114, 117, 242, 0);
    }
}

.ai_gradient_button_text {
    font-size: inherit;
    font-weight: inherit;
    white-space: nowrap;
}