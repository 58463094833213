/* Webpixels CSS */
/* Utility and component-centric Design System based on Bootstrap for fast, responsive UI development */
/* URL: https://github.com/webpixels/css */

@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");


/* Admin Login Dialox box */

.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-heading {
    text-align: center;
    margin-bottom: 20px;
}

.admin-form-group {
    margin-bottom: 20px;
}

.admin-form-group label {
    display: block;
    font-weight: bold;
}

.admin-form-group input[type="text"],
.admin-form-group input[type="password"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.admin-btn-login {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #6366f1;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.admin-btn-login:hover {
    background-color: #4f46e5;
}

.red-text {
    color: red !important;
    font-weight: bold;
    cursor: pointer;
}

/* Search Bar */

.searchbar-wrapper-admin {
    position: relative;
    display: flex;

}

.tooltip-box {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 200px;
    border-radius: 8px;
}

.tooltip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;

}

.tooltip-options {
    display: flex;
    flex-direction: column;
}

.tooltip-options button {
    margin-bottom: 10px;

}

.tooltip-options button:hover {
    background-color: #f0f0f0;
    /* Added hover color */
}

.clear-btn {
    background-color: #6366f1;
    /* Updated color */
    color: white;
    border-radius: 4px;
    /* Added border radius */
    transition: background-color 0.3s;
    /* Added transition for smooth hover effect */
}

.clear-btn:hover {
    background-color: #4f46e5 !important;

}

.close-btn {
    background-color: transparent;
    border: none;
    font-size: 16px;
}

.minimized-modal-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #5C60F5;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}