.chip {
    display: inline-block;
    padding: 2px 20px;
    /* Decreased padding for a slimmer look */
    border-radius: 16px;
    /* Reduced border-radius */
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin: 8px;
    user-select: none;
    border: 1px solid transparent;
    /* Adding a border to match the styles */
}

.blue {
    background-color: #E3F2FD;
    /* Light Blue Background */
    color: #1E88E5;
    /* Dark Blue Text */
    border-color: #1E88E5;
    /* Dark Blue Border */
}

.red {
    background-color: #FFEBEE;
    /* Light Red Background */
    color: #E53935;
    /* Dark Red Text */
    border-color: #E53935;
    /* Dark Red Border */
}

.green {
    background-color: #E8F5E9;
    /* Light Green Background */
    color: #43A047;
    /* Dark Green Text */
    border-color: #43A047;
    /* Dark Green Border */
}

.orange {
    background-color: #FFF3E0;
    /* Light Orange Background */
    color: #FB8C00;
    /* Dark Orange Text */
    border-color: #FB8C00;
    /* Dark Orange Border */
}

.gray {
    background-color: #F5F5F5;
    /* Light Gray Background */
    color: #757575;
    /* Dark Gray Text */
    border-color: #757575;
    /* Dark Gray Border */
}