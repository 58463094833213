.cookies-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    /* White background with 50% opacity */
    z-index: 999;
    /* Ensures it appears above other content */
}

/* Center the modal box */
.cookies-modal-card {
    width: 300px;
    height: 220px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    gap: 13px;
    position: absolute;
    /* Absolute positioning to center the modal */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Centers the modal */
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062), 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    /* Ensures the modal is above the overlay */
}

#cookies-modal-cookieSvg {
    width: 50px;
}

#cookies-modal-cookieSvg g path {
    fill: rgb(97, 81, 81);
}

.cookies-modal-cookieHeading {
    font-size: 1.2em;
    font-weight: 800;
    color: rgb(26, 26, 26);
}

.cookies-modal-cookieDescription {
    text-align: center;
    font-size: 0.7em;
    font-weight: 600;
    color: rgb(99, 99, 99);
    margin-bottom: 10px;
    /* Added bottom margin */
}

.cookies-modal-cookieDescription a {
    --tw-text-opacity: 1;
    color: rgb(59 130 246);
}

.cookies-modal-cookieDescription a:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
}

.cookies-modal-buttonContainer {
    display: flex;
    gap: 20px;
    flex-direction: row;
}

.cookies-modal-acceptButton {
    width: 80px;
    height: 30px;
    background-color: #7b57ff;
    transition-duration: .2s;
    border: none;
    color: rgb(241, 241, 241);
    cursor: pointer;
    font-weight: 600;
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px #977ef3, 0 2px 4px -1px #977ef3;
    transition: all .6s ease;
}

.cookies-modal-secondButton {
    min-width: 80px;
    height: 30px;
    background-color: #dadada;
    transition-duration: .2s;
    color: rgb(46, 46, 46);
    border: none;
    cursor: not-allowed;
    font-weight: 600;
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px #bebdbd, 0 2px 4px -1px #bebdbd;
    transition: all .6s ease;
    padding: 0 10px;
    /* Added padding on left and right */
}

.cookies-modal-secondButton:hover {
    background-color: #ebebeb;
    box-shadow: 0 10px 15px -3px #bebdbd, 0 4px 6px -2px #bebdbd;
    transition-duration: .2s;
}

.cookies-modal-acceptButton:hover {
    background-color: #9173ff;
    box-shadow: 0 10px 15px -3px #977ef3, 0 4px 6px -2px #977ef3;
    transition-duration: .2s;
}