.dropdown-container {
    position: relative;
}

.table-cell-wrap {
    max-width: 200px;
    /* Adjust the max width as needed */
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
}

.skills-dropdown {
    width: 100%;
    max-height: 150px;
    /* Adjust as needed */
    overflow-y: auto;
}

.save-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}