.file_upload_container {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.file_upload_form {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file_upload_label {
    cursor: pointer;
    background-color: #ddd;
    padding: 30px 70px;
    border-radius: 40px;
    border: 2px dashed rgb(82, 82, 82);
    box-shadow: 0px 0px 200px -50px rgba(0, 0, 0, 0.719);
}

.file_upload_label input {
    display: none;
}

.file_upload_icon {
    height: 50px;
    fill: #6366f1;
    margin-bottom: 20px;
}

.file_upload_design {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.file_upload_browse_button {
    display: flex;
    background-color: #6366f1;
    padding: 5px 15px;
    border-radius: 10px;
    color: white;
    transition: all 0.3s;
    text-align: center;
}

.file_upload_browse_button:hover {
    background-color: #4f46e5;
}


.close_button {
    position: absolute;
    top: -25px;
    right: -15px;
    background: none;
    border: none;
    font-size: 48px;
    cursor: pointer;
    color: #666;
    padding: 5px 10px;
}

.close_button:hover {
    color: #333;
}