@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Nothing+You+Could+Do&display=fallback);

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}

::before,
::after {
    --tw-content: ''
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-feature-settings: normal
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,
[role="button"] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

.password-input-wrapper {
    position: relative;
}

.password-input-container {
    position: relative;
}

.password-input {
    padding-right: 40px;
    /* Adjust this value based on your icon size */
}

.password-toggle-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.password-icon {
    width: 20px;
    /* Adjust size as needed */
    height: 20px;
    /* Adjust size as needed */
    color: #6b7280;
    /* Adjust color as needed */
}

.password-hint {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
    line-height: 1.4;
    margin-bottom: 20px;
}





/* DROP DOWN BOX STYLING */


/* ... existing code ... */

.tech-stack-dropdown {
    background-color: #fff;
    border: 1px solid #6b7280;
    border-radius: 0.25rem;
    padding: 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
}

.tech-stack-dropdown div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.tech-stack-dropdown input[type="checkbox"] {
    margin-right: 0.5rem;
    appearance: none;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #6366f1;
    /* Updated color */
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0.125rem;
    --tw-shadow: 0 0 #0000;
}

.tech-stack-dropdown input[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg%20viewBox='0%200%2016%2016'%20fill='white'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M12.207%204.793a1%201%200%20010%201.414l-5%205a1%201%200%2001-1.414%200l-2-2a1%201%200%20011.414-1.414L6.5%209.086l4.293-4.293a1%201%200%20011.414%200z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.tech-stack-dropdown button {
    background-color: #6366f1;
    color: #fff;
    border: none;
    border-radius: 9999px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
    /* Prevent underline */
}

.tech-stack-dropdown button:hover {
    background-color: #4f46e5;
    text-decoration: none;
    color: white;
    /* Prevent underline */
}

.selected-tech-stack {
    margin-top: 0.5rem;
}

.tech-stack-tag {
    display: inline-flex;
    align-items: center;
    background-color: #e0e7ff;
    color: #6366f1;
    border-radius: 9999px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.remove-skill-btn {
    background-color: #6366f1;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    transition: background-color 0.3s ease;
    padding: 0;
    line-height: 1;
}

.remove-skill-btn:hover {
    background-color: #4f46e5;
}

/* Promoted Chip */

.promoted-ribbon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: #6366f1;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); */
    z-index: 10;

}

/* ... TOOLTIP ... */

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.7);
    /* Reduced opacity to 0.7 */
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

/* Added triangular pointer */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* Price Strike Styling */


.price-strike {
    text-decoration: line-through;
    text-decoration-thickness: 1.5px;
    /* Increased thickness of the strike through line */
    color: #525f7f;
    /* Adjust color as needed */
    margin-right: 5px;
    /* Space between price and FREE text */
}

.free-text {
    color: #6f42c1;
    /* Purple color (adjust to match your theme) */
    font-weight: bold;
}

[hidden] {
    display: none
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #2563eb
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #6b7280;
    opacity: 1
}

input::placeholder,
textarea::placeholder {
    color: #6b7280;
    opacity: 1
}

::-webkit-datetime-edit-fields-wrapper {
    padding: 0
}

::-webkit-date-and-time-value {
    min-height: 1.5em
}

::-webkit-datetime-edit,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0
}

select {
    background-image: url(data:image/svg+xml,%3csvg\ xmlns=\'http://www.w3.org/2000/svg\'\ fill=\'none\'\ viewBox=\'0\ 0\ 20\ 20\'%3e%3cpath\ stroke=\'%236b7280\'\ stroke-linecap=\'round\'\ stroke-linejoin=\'round\'\ stroke-width=\'1.5\'\ d=\'M6\ 8l4\ 4\ 4-4\'/%3e%3c/svg%3e);
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact
}

[multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    padding-right: 0.75rem;
    -webkit-print-color-adjust: unset;
    print-color-adjust: unset
}

[type="checkbox"],
[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000
}

[type="checkbox"] {
    border-radius: 0px
}

[type="radio"] {
    border-radius: 100%
}

[type="checkbox"]:focus,
[type="radio"]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow)
}

[type="checkbox"]:checked,
[type="radio"]:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

[type="checkbox"]:checked {
    background-image: url(data:image/svg+xml,%3csvg\ viewBox=\'0\ 0\ 16\ 16\'\ fill=\'white\'\ xmlns=\'http://www.w3.org/2000/svg\'%3e%3cpath\ d=\'M12.207\ 4.793a1\ 1\ 0\ 010\ 1.414l-5\ 5a1\ 1\ 0\ 01-1.414\ 0l-2-2a1\ 1\ 0\ 011.414-1.414L6.5\ 9.086l4.293-4.293a1\ 1\ 0\ 011.414\ 0z\'/%3e%3c/svg%3e)
}

[type="radio"]:checked {
    background-image: url(data:image/svg+xml,%3csvg\ viewBox=\'0\ 0\ 16\ 16\'\ fill=\'white\'\ xmlns=\'http://www.w3.org/2000/svg\'%3e%3ccircle\ cx=\'8\'\ cy=\'8\'\ r=\'3\'/%3e%3c/svg%3e)
}

[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
    border-color: transparent;
    background-color: currentColor
}

[type="checkbox"]:indeterminate {
    background-image: url(data:image/svg+xml,%3csvg\ xmlns=\'http://www.w3.org/2000/svg\'\ fill=\'none\'\ viewBox=\'0\ 0\ 16\ 16\'%3e%3cpath\ stroke=\'white\'\ stroke-linecap=\'round\'\ stroke-linejoin=\'round\'\ stroke-width=\'2\'\ d=\'M4\ 8h8\'/%3e%3c/svg%3e);
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

[type="checkbox"]:indeterminate:hover,
[type="checkbox"]:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor
}

[type="file"] {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit
}

[type="file"]:focus {
    outline: 1px solid ButtonText;
    outline: 1px auto -webkit-focus-ring-color
}

*,
::before,
::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.nowrap {
    white-space: nowrap;
}

.cmcze {
    width: 100%
}

@media (min-width:640px) {
    .cmcze {
        max-width: 640px
    }
}

@media (min-width:768px) {
    .cmcze {
        max-width: 768px
    }
}

@media (min-width:1024px) {
    .cmcze {
        max-width: 1024px
    }
}

@media (min-width:1280px) {
    .cmcze {
        max-width: 1280px
    }
}

@media (min-width:1536px) {
    .cmcze {
        max-width: 1536px
    }
}

.cule2,
.c8occ,
.coiqx,
.cefgp {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000
}

.cule2:focus,
.c8occ:focus,
.coiqx:focus,
.cefgp:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #2563eb
}

.cule2::-moz-placeholder,
.c8occ::-moz-placeholder {
    color: #6b7280;
    opacity: 1
}

.cule2::placeholder,
.c8occ::placeholder {
    color: #6b7280;
    opacity: 1
}

.cule2::-webkit-datetime-edit-fields-wrapper {
    padding: 0
}

.cule2::-webkit-date-and-time-value {
    min-height: 1.5em
}

.cule2::-webkit-datetime-edit,
.cule2::-webkit-datetime-edit-year-field,
.cule2::-webkit-datetime-edit-month-field,
.cule2::-webkit-datetime-edit-day-field,
.cule2::-webkit-datetime-edit-hour-field,
.cule2::-webkit-datetime-edit-minute-field,
.cule2::-webkit-datetime-edit-second-field,
.cule2::-webkit-datetime-edit-millisecond-field,
.cule2::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0
}

.coiqx {
    background-image: url(data:image/svg+xml,%3csvg\ xmlns=\'http://www.w3.org/2000/svg\'\ fill=\'none\'\ viewBox=\'0\ 0\ 20\ 20\'%3e%3cpath\ stroke=\'%236b7280\'\ stroke-linecap=\'round\'\ stroke-linejoin=\'round\'\ stroke-width=\'1.5\'\ d=\'M6\ 8l4\ 4\ 4-4\'/%3e%3c/svg%3e);
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact
}

.cicja,
.cmzxr {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000
}

.cicja {
    border-radius: 0px
}

.cmzxr {
    border-radius: 100%
}

.cicja:focus,
.cmzxr:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow)
}

.cicja:checked,
.cmzxr:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

.cicja:checked {
    background-image: url(data:image/svg+xml,%3csvg\ viewBox=\'0\ 0\ 16\ 16\'\ fill=\'white\'\ xmlns=\'http://www.w3.org/2000/svg\'%3e%3cpath\ d=\'M12.207\ 4.793a1\ 1\ 0\ 010\ 1.414l-5\ 5a1\ 1\ 0\ 01-1.414\ 0l-2-2a1\ 1\ 0\ 011.414-1.414L6.5\ 9.086l4.293-4.293a1\ 1\ 0\ 011.414\ 0z\'/%3e%3c/svg%3e)
}

.cmzxr:checked {
    background-image: url(data:image/svg+xml,%3csvg\ viewBox=\'0\ 0\ 16\ 16\'\ fill=\'white\'\ xmlns=\'http://www.w3.org/2000/svg\'%3e%3ccircle\ cx=\'8\'\ cy=\'8\'\ r=\'3\'/%3e%3c/svg%3e)
}

.cicja:checked:hover,
.cicja:checked:focus,
.cmzxr:checked:hover,
.cmzxr:checked:focus {
    border-color: transparent;
    background-color: currentColor
}

.cicja:indeterminate {
    background-image: url(data:image/svg+xml,%3csvg\ xmlns=\'http://www.w3.org/2000/svg\'\ fill=\'none\'\ viewBox=\'0\ 0\ 16\ 16\'%3e%3cpath\ stroke=\'white\'\ stroke-linecap=\'round\'\ stroke-linejoin=\'round\'\ stroke-width=\'2\'\ d=\'M4\ 8h8\'/%3e%3c/svg%3e);
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

.cicja:indeterminate:hover,
.cicja:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor
}

.ckqm5 {
    font-size: 3rem;
    line-height: 1;
    letter-spacing: -0.01em;
    font-weight: 800
}

.cisk3 {
    font-size: 2.25rem;
    line-height: 1.277;
    letter-spacing: -0.01em;
    font-weight: 800
}

.c1y4l {
    font-size: 1.875rem;
    line-height: 1.333;
    letter-spacing: -0.01em;
    font-weight: 800
}

.ckgze {
    font-size: 1.5rem;
    line-height: 1.415;
    letter-spacing: -0.01em;
    font-weight: 800
}

@media (min-width:768px) {
    .ckqm5 {
        font-size: 3.75rem;
        line-height: 1;
        letter-spacing: -0.01em
    }

    .cisk3 {
        font-size: 3rem;
        line-height: 1;
        letter-spacing: -0.01em
    }
}

.c0tyr,
.c40tu {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 9999px;
    font-size: 0.875rem;
    line-height: 1.5715;
    font-weight: 500;
    line-height: 1.25rem;
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.c0tyr {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
}

.c40tu {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none
}

.cule2,
.c8occ,
.cefgp,
.coiqx,
.cicja,
.cmzxr {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219/var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.cule2:focus,
.c8occ:focus,
.cefgp:focus,
.coiqx:focus,
.cicja:focus,
.cmzxr:focus {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241/var(--tw-border-opacity))
}

.cule2,
.c8occ,
.cefgp,
.coiqx,
.cicja {
    border-radius: 0.25rem
}

.cule2,
.c8occ,
.cefgp,
.coiqx {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5715
}

.cule2::-moz-placeholder,
.c8occ::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(156 163 175/var(--tw-placeholder-opacity))
}

.cule2::placeholder,
.c8occ::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(156 163 175/var(--tw-placeholder-opacity))
}

.coiqx {
    padding-right: 2.5rem
}

.cicja,
.cmzxr {
    border-radius: 0.125rem;
    border-width: 2px;
    --tw-text-opacity: 1;
    color: rgb(99 102 241/var(--tw-text-opacity))
}

.c29o4::-webkit-scrollbar {
    display: none
}

.c29o4 {
    -ms-overflow-style: none;
    scrollbar-width: none
}

:root {
    --range-thumb-size: 36px
}

input[type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #ccc;
    border-radius: 3px;
    height: 6px;
    margin-top: (--range-thumb-size - 6px)*0.5;
    margin-bottom: (--range-thumb-size - 6px)*0.5;
    --thumb-size: #{--range-thumb-size}
}

input[type=range]::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: #000;
    background-image: url(data:image/svg+xml,%3Csvg\ width=\'12\'\ height=\'8\'\ xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath\ d=\'M8\ .5v7L12\ 4zM0\ 4l4\ 3.5v-7z\'\ fill=\'%23FFF\'\ fill-rule=\'nonzero\'/%3E%3C/svg%3E);
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size
}

input[type=range]::-moz-range-thumb {
    background-color: #000;
    background-image: url(data:image/svg+xml,%3Csvg\ width=\'12\'\ height=\'8\'\ xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath\ d=\'M8\ .5v7L12\ 4zM0\ 4l4\ 3.5v-7z\'\ fill=\'%23FFF\'\ fill-rule=\'nonzero\'/%3E%3C/svg%3E);
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size
}

input[type=range]::-ms-thumb {
    background-color: #000;
    background-image: url(data:image/svg+xml,%3Csvg\ width=\'12\'\ height=\'8\'\ xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath\ d=\'M8\ .5v7L12\ 4zM0\ 4l4\ 3.5v-7z\'\ fill=\'%23FFF\'\ fill-rule=\'nonzero\'/%3E%3C/svg%3E);
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size
}

input[type=range]::-moz-focus-outer {
    border: 0
}

.cumo3 {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 44px
}

.cumo3 label {
    display: block;
    height: 1.5rem;
    cursor: pointer;
    overflow: hidden;
    border-radius: 9999px
}

.cumo3 label>span:first-child {
    position: absolute;
    display: block;
    border-radius: 9999px;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    right: 50%;
    transition: all .15s ease-out
}

.cumo3 input[type="checkbox"]:checked+label {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241/var(--tw-bg-opacity))
}

.cumo3 input[type="checkbox"]:checked+label>span:first-child {
    left: 22px
}

.cumo3 input[type="checkbox"]:disabled+label {
    cursor: not-allowed;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235/var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246/var(--tw-bg-opacity))
}

.cumo3 input[type="checkbox"]:disabled+label>span:first-child {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175/var(--tw-bg-opacity))
}

.cule2:focus:focus,
.c8occ:focus:focus,
.cefgp:focus:focus,
.coiqx:focus:focus,
.cicja:focus:focus,
.cmzxr:focus:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity))
}

.cicja:focus:focus,
.cmzxr:focus:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.cl1rf svg>*:nth-child(1),
.cl1rf svg>*:nth-child(2),
.cl1rf svg>*:nth-child(3) {
    transform-origin: center;
    transform: rotate(0deg)
}

.cl1rf svg>*:nth-child(1) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in
}

.cl1rf svg>*:nth-child(2) {
    transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)
}

.cl1rf svg>*:nth-child(3) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in
}

.cl1rf.c0dvg svg>*:nth-child(1) {
    opacity: 0;
    y: 11;
    transform: rotate(225deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out
}

.cl1rf.c0dvg svg>*:nth-child(2) {
    transform: rotate(225deg);
    transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.cl1rf.c0dvg svg>*:nth-child(3) {
    y: 11;
    transform: rotate(135deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out
}

.cxbaz {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}

.cetod {
    pointer-events: none
}

.c5k7i {
    visibility: visible
}

.c7v80 {
    visibility: collapse
}

.ceq8v {
    position: fixed
}

.c6kdq {
    position: absolute
}

.cwq9z {
    position: relative
}

.c5uys {
    position: sticky
}

.cjxga {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.cg1xx {
    bottom: 0px
}

.c8zq8 {
    left: 50%
}

.cyzod {
    right: 0px
}

.cv23o {
    right: 1.25rem
}

.c753i {
    top: 0px
}

.cx5ip {
    top: 1.25rem
}

.ctbeu {
    top: 2rem
}

.c8f1b {
    z-index: -10
}

.ckof8 {
    z-index: 30
}

.coscr {
    order: -1
}

.c0zhl {
    margin: -0.25rem
}

.cg9ly {
    margin: 0px
}

.cfhya {
    margin: 0.25rem
}

.c1c72 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
}

.cfd1l {
    margin-left: auto;
    margin-right: auto
}

.cwtaw {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.cmpn4 {
    margin-left: -0px
}

.cs3h1 {
    margin-left: -0.125rem
}

.ctl16 {
    margin-top: -0px
}

.cvsgv {
    margin-top: -0.125rem
}

.cdw8o {
    margin-top: -0.25rem
}

.cqmuz {
    margin-top: -0.375rem
}

.ck6q6 {
    margin-bottom: 0.25rem
}

.c6cl6 {
    margin-bottom: 2.5rem
}

.cxjem {
    margin-bottom: 3rem
}

.cmi0k {
    margin-bottom: 0.5rem
}

.cd67x {
    margin-bottom: 0.75rem
}

.cvxp4 {
    margin-bottom: 1rem
}

.cip9s {
    margin-bottom: 1.25rem
}

.ccymi {
    margin-bottom: 1.5rem
}

.cjx7n {
    margin-bottom: 2rem
}

.clnaa {
    margin-bottom: auto
}

.ce3ah {
    margin-left: 0.25rem
}

.cvhuf {
    margin-left: 0.5rem
}

.c75a8 {
    margin-left: 0.75rem
}

.c59y5 {
    margin-right: 0.75rem
}

.ch3n4 {
    margin-right: 1rem
}

.cccju {
    margin-top: 0px
}

.cauim {
    margin-top: 0.25rem
}

.c1gcw {
    margin-top: 0.375rem
}

.clnyg {
    margin-top: 0.5rem
}

.cs9kl {
    margin-top: 1rem
}

.cfait {
    margin-top: 1.5rem
}

.c10p4 {
    margin-top: 2rem
}

.c52nt {
    box-sizing: content-box
}

.cohq9 {
    display: block
}

.ctaf6 {
    display: inline
}

.c8og8 {
    display: flex
}

.cy6kr {
    display: inline-flex
}

.c8dcm {
    display: grid
}

.cndjv {
    display: none
}

.caqh0 {
    height: 4rem
}

.cpp7g {
    height: 6rem
}

.cre3a {
    height: 0.75rem
}

.cwcof {
    height: 1rem
}

.c4lqp {
    height: 2rem
}

.cau4d {
    height: 100%
}

.cbrwb {
    min-height: 100vh
}

.cz79n {
    width: 50%
}

.cb8dy {
    width: 4rem
}

.cr84d {
    width: 0.75rem
}

.c0mq4 {
    width: 1rem
}

.c19rb {
    width: 2rem
}

.cn582 {
    width: 100%
}

.co7yr {
    min-width: 120px
}

.clxkp {
    max-width: 48rem
}

.cvq1y {
    max-width: 56rem
}

.cnsl7 {
    max-width: 72rem
}

.c8tvk {
    max-width: 100%
}

.c48gu {
    max-width: 28rem
}

.co24w {
    max-width: none
}

.c0usk {
    max-width: 24rem
}

.cm1jm {
    max-width: 36rem
}

.c1vmp {
    max-width: 20rem
}

.ce3tb {
    flex: 1 1 0%
}

.cvu8r {
    flex: 1 1 auto
}

.ctzpc {
    flex-shrink: 0
}

.cycys {
    flex-grow: 1
}

.codga {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.c3soh {
    --tw-rotate: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cfqex {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cu1zo {
    cursor: pointer
}

.cr41u {
    resize: both
}

.cd40q {
    list-style-position: inside
}

.cb1aa {
    list-style-type: disc
}

ul.cb1aa {
    list-style-type: disc;
    margin-left: 1em;
    padding-left: 1em;
}

ul.cb1aa li {
    text-indent: -1.3em;
    /* Adjust this value according to your needs */
    padding-left: 1em;
    /* Same as the margin-left to align the first line correctly */
    margin-bottom: 0.5em;
    /* Optional: Adds some space between list items */
}

.c12va {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.c3ma2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.ca7zr {
    flex-direction: column
}

.cvkqj {
    flex-wrap: wrap
}

.c5li5 {
    align-items: flex-start
}

.cduop {
    align-items: center
}

.cemyy {
    justify-content: flex-end
}

.c3tj1 {
    justify-content: center
}

.chu3i {
    justify-content: space-between
}

.ckxup {
    justify-content: space-around
}

.cx1zb {
    gap: 1.5rem
}

.csd9h>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem*var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem*calc(1 - var(--tw-space-x-reverse)))
}

.ccgu6>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem*var(--tw-space-x-reverse));
    margin-left: calc(0.5rem*calc(1 - var(--tw-space-x-reverse)))
}

.c40vg>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem*var(--tw-space-x-reverse));
    margin-left: calc(0.75rem*calc(1 - var(--tw-space-x-reverse)))
}

.cu4k7>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem*var(--tw-space-x-reverse));
    margin-left: calc(1rem*calc(1 - var(--tw-space-x-reverse)))
}

.cv4ej>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem*var(--tw-space-x-reverse));
    margin-left: calc(1.25rem*calc(1 - var(--tw-space-x-reverse)))
}

.c2i7x>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem*var(--tw-space-y-reverse))
}

.czodn>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem*var(--tw-space-y-reverse))
}

.cb7bz>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem*var(--tw-space-y-reverse))
}

.cbo0x>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem*var(--tw-space-y-reverse))
}

.cyhe5>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem*var(--tw-space-y-reverse))
}

.cswe3>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem*var(--tw-space-y-reverse))
}

.ctkqc>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem*var(--tw-space-y-reverse))
}

.c3217>:not([hidden])~:not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px*calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px*var(--tw-divide-y-reverse))
}

.cuiez>:not([hidden])~:not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235/var(--tw-divide-opacity))
}

.job-card {
    transition: background-color 0.3s ease;
}

.job-card-hover:hover {
    background-color: #e0e7ff;
    border-radius: 10px;
}

.job-card-badges {
    width: 100%;
}

@media (min-width: 1024px) {
    .job-card-badges {
        width: 375px;
    }
}

.project-type-chip {
    display: flex;
    align-items: center;
    border: 1px solid #cbd5e1;
    /* slate-300 equivalent */
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 0.875rem;
    /* text-sm */
    color: #475569;
    /* slate-600 equivalent */
    text-align: center;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}


.hide-on-small {
    display: none;
}

@media (min-width: 768px) {

    /* Show on larger screens */
    .hide-on-small {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}


.cj019 {
    overflow: hidden
}

.ch6sm {
    white-space: nowrap
}

.clo09 {
    border-radius: 0.25rem
}

.c1g5q {
    border-radius: 9999px
}

.ceip1 {
    border-radius: 0.375rem
}

.c7z1d {
    border-radius: 0.75rem
}

.cqwhl {
    border-width: 1px
}

.cxjr3 {
    border-width: 0px
}

.caz15 {
    border-width: 2px
}

.cokkt {
    border-bottom-width: 1px
}

.crv3l {
    border-bottom-width: 2px
}

.c9gvg {
    border-top-width: 1px
}

.czhnl {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235/var(--tw-border-opacity))
}

.cogpj {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219/var(--tw-border-opacity))
}

.c4146 {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255/var(--tw-border-opacity))
}

.cfxpd {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241/var(--tw-border-opacity))
}

.crhpi {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253/var(--tw-border-opacity))
}

.ch4ps {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228/var(--tw-border-opacity))
}

.cn9ee {
    border-color: transparent
}

.cdxuw {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246/var(--tw-bg-opacity))
}

.cfozt {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235/var(--tw-bg-opacity))
}

.cg72j {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219/var(--tw-bg-opacity))
}

.c2cn0 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity))
}

.ct79c {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255/var(--tw-bg-opacity))
}

.c49bb {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255/var(--tw-bg-opacity))
}

.ce7qj {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241/var(--tw-bg-opacity))
}

.cnh8b {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94/var(--tw-bg-opacity))
}

.c0s3t {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255/var(--tw-bg-opacity))
}

.ch7jl {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250/var(--tw-bg-opacity))
}

.cz0l8 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.cfcbm {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.ctjxe {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-to: rgb(224 231 255 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.cjcd5 {
    --tw-gradient-to: #fff
}

.c13js {
    fill: #fbbf24
}

.ca4xu {
    fill: currentColor
}

.cjqf2 {
    fill: #9ca3af
}

.cki6n {
    fill: #a5b4fc
}

.cflao {
    fill: #818cf8
}

.cdzk3 {
    fill: #6366f1
}

.c96oa {
    fill: #14b8a6
}

.c0xth {
    -o-object-fit: cover;
    object-fit: cover
}

.cykk9 {
    padding: 0.5rem
}

.ct0d8 {
    padding: 1rem
}

.ck73f {
    padding: 1.25rem
}

.cag1b {
    padding-left: 0px;
    padding-right: 0px
}

.cz1dt {
    padding-left: 0.125rem;
    padding-right: 0.125rem
}

.ce33e {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}

.c50eh {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}

.cwkz1 {
    padding-left: 1rem;
    padding-right: 1rem
}

.cn4py {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.cmcfj {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.cs4uy {
    padding-top: 0px;
    padding-bottom: 0px
}

.c7d26 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem
}

.c2jq4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
}

.c2fn4 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
}

.csxam {
    padding-top: 3rem;
    padding-bottom: 3rem
}

.cm7rx {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}

.cp9oo {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
}

.c0qn4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.coh2n {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.cmn18 {
    padding-bottom: 0.25rem
}

.c8d05 {
    padding-bottom: 2rem
}

.cdelr {
    padding-left: 0.75rem
}

.cef3h {
    padding-top: 7rem
}

.cef3hw {
    padding-top: 2rem
}

.cm4vd {
    text-align: left
}

.czbam {
    text-align: center
}

.cuad0 {
    font-family: Inter, sans-serif
}

.c1laq {
    font-family: Nothing You Could Do, cursive
}

.cu7if {
    font-size: 1.5rem;
    line-height: 1.415;
    letter-spacing: -0.01em
}

.cfibj {
    font-size: 1.875rem;
    line-height: 1.333;
    letter-spacing: -0.01em
}

.cgmpd {
    font-size: 2.25rem;
    line-height: 1.277;
    letter-spacing: -0.01em
}

.cqdqx {
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: -0.01em
}

.cf37z {
    font-size: 0.875rem;
    line-height: 1.5715
}

.cevzo {
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: -0.01em
}

.c9eyc {
    font-size: 0.75rem;
    line-height: 1.5
}

.clt06 {
    font-weight: 700
}

.cmz24 {
    font-weight: 800
}

.c4vrg {
    font-weight: 500
}

.chgw8 {
    font-weight: 400
}

.c3774 {
    font-weight: 600
}

.c75bs {
    font-style: italic
}

.cv5zz {
    line-height: 1
}

.ccolo {
    letter-spacing: 0
}

.c1222 {
    letter-spacing: -0.01em
}

.c8vjo {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity))
}

.c2ny4 {
    --tw-text-opacity: 1;
    color: rgb(5 150 105/var(--tw-text-opacity))
}

.ccyd8 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity))
}

.ca6yp {
    --tw-text-opacity: 1;
    color: rgb(107 114 128/var(--tw-text-opacity))
}

.cm87k {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity))
}

.cwyp5 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity))
}

.cznhr {
    --tw-text-opacity: 1;
    color: rgb(31 41 55/var(--tw-text-opacity))
}

.c7hvu {
    --tw-text-opacity: 1;
    color: rgb(17 24 39/var(--tw-text-opacity))
}

.cndda {
    --tw-text-opacity: 1;
    color: rgb(199 210 254/var(--tw-text-opacity))
}

.cw2fq {
    --tw-text-opacity: 1;
    color: rgb(99 102 241/var(--tw-text-opacity));
}

.cw2fq:hover {
    color: rgb(79 70 229/var(--tw-text-opacity));
}

.c2xae {
    --tw-text-opacity: 1;
    color: rgb(79 70 229/var(--tw-text-opacity))
}

.corvs {
    --tw-text-opacity: 1;
    color: rgb(239 68 68/var(--tw-text-opacity))
}

.ckiea {
    --tw-text-opacity: 1;
    color: rgb(254 205 211/var(--tw-text-opacity))
}

.cmkam {
    --tw-text-opacity: 1;
    color: rgb(255 241 242/var(--tw-text-opacity))
}

.c0ix9 {
    --tw-text-opacity: 1;
    color: rgb(244 63 94/var(--tw-text-opacity))
}

.cpjz5 {
    --tw-text-opacity: 1;
    color: rgb(14 165 233/var(--tw-text-opacity))
}

.c0bjb {
    --tw-text-opacity: 1;
    color: rgb(20 184 166/var(--tw-text-opacity))
}

.cyky3 {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.csjdn {
    text-decoration-line: underline
}

.cztc9 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.c3z1d {
    opacity: 0.3
}

.c0s5u {
    opacity: 0.75
}

.ctpla {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ciekg {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.c0k6h {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity))
}

.cikjx {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.cww2f {
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.clfy9 {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.comjk {
    transition-duration: 150ms
}

.cmwpt {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

[x-cloak=""] {
    display: none
}

.c3sa8::file-selector-button {
    margin-right: 1rem
}

.cs1ft::file-selector-button {
    border-radius: 9999px
}

.cvfxr::file-selector-button {
    border-width: 0px
}

.cjokd::file-selector-button {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241/var(--tw-bg-opacity))
}

.clujg::file-selector-button {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}

.clajw::file-selector-button {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
}

.ct3l0::file-selector-button {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
}

.cyp4d::file-selector-button {
    font-size: 0.875rem;
    line-height: 1.5715
}

.cc88m::file-selector-button {
    font-weight: 500
}

.cjtf9::file-selector-button {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.cbjuw::after {
    content: var(--tw-content);
    margin-top: auto
}

.cvcg6::after {
    content: var(--tw-content);
    flex: 1 1 0%
}

.cczsy::after {
    content: var(--tw-content);
    padding-left: 0.5rem;
    padding-right: 0.5rem
}

.c0hwz::after {
    content: var(--tw-content);
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity))
}

.cgvm3::after {
    --tw-content: '·';
    content: var(--tw-content)
}

.c43gp:last-child::after {
    content: var(--tw-content);
    display: none
}

.casko:nth-child(odd) {
    --tw-rotate: 1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.csmwo:nth-child(even) {
    --tw-rotate: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cao76:hover {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.c16qw:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241/var(--tw-bg-opacity))
}

.czwz3:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229/var(--tw-bg-opacity))
}

.cbr86:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72/var(--tw-bg-opacity))
}

.cjzxy:hover {
    --tw-text-opacity: 1;
    color: rgb(107 114 128/var(--tw-text-opacity))
}

.cv6oq:hover {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity))
}

.cve8r:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.c4von:hover {
    text-decoration-line: underline
}

.cdrqk:hover {
    opacity: 1
}

.cwsbp::file-selector-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229/var(--tw-bg-opacity))
}

.cq9g1:focus {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0/var(--tw-border-opacity))
}

.cgpu3:focus {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219/var(--tw-border-opacity))
}

.ccmhc:focus {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128/var(--tw-border-opacity))
}

.c30y9:focus {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252/var(--tw-border-opacity))
}

.cbiem:focus {
    border-color: transparent
}

.ccp2c:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235/var(--tw-bg-opacity))
}

.cefd5:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.cg9a1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ct8w1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.cyiy4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.cp6al:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity))
}

.cyxo2:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity))
}

.cu0px:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity))
}

.cgnvp:focus {
    --tw-ring-opacity: 0.5
}

.cyhtr:focus {
    --tw-ring-offset-width: 0px
}

.cjfrw:focus {
    --tw-ring-offset-width: 2px
}

.c6kou:hover .c5mn7 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.c6kou:hover .c8bku {
    --tw-translate-x: 0.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.c6kou:hover .cjlhc {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@supports (overflow:clip) {
    .c3nl1 {
        overflow: clip
    }
}

@media (min-width:640px) {
    .ccmg5 {
        position: absolute
    }

    .ct5p9 {
        margin-bottom: 0px
    }

    .c92ql {
        margin-right: 0.5rem
    }

    .cp1au {
        display: flex
    }

    .czt4f {
        width: 33.333333%
    }

    .crk6p {
        max-width: none
    }

    .cti7q {
        flex-direction: row
    }

    .c8p1b {
        align-items: center
    }

    .crj19 {
        justify-content: center
    }

    .cypyb>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.75rem*var(--tw-space-x-reverse));
        margin-left: calc(0.75rem*calc(1 - var(--tw-space-x-reverse)))
    }

    .c6rln>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.25rem*var(--tw-space-x-reverse));
        margin-left: calc(1.25rem*calc(1 - var(--tw-space-x-reverse)))
    }

    .cclz8>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px*calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px*var(--tw-space-y-reverse))
    }

    .cwkio {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .cmiux {
        text-align: left
    }
}

@media (min-width:768px) {
    .crf2m {
        order: 1
    }

    .cjsxg {
        order: 2
    }

    .cc7q4 {
        margin-bottom: 0px
    }

    .cd2ei {
        margin-bottom: 1.5rem
    }

    .ctnpq {
        margin-left: 3rem
    }

    .cx8hc {
        margin-left: 1rem
    }

    .c9rix {
        display: block
    }

    .cwa15 {
        display: flex
    }

    .c1ymg {
        height: 5rem
    }

    .cmvfi {
        width: 16rem
    }

    .c6ja6 {
        max-width: 56rem
    }

    .cu2gg {
        flex-shrink: 0
    }

    .cd11c {
        flex-grow: 1
    }

    .cr8k3 {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .cqpov {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .ciyoj {
        flex-direction: row
    }

    .czgjk {
        align-items: center
    }

    .cua4c {
        justify-content: flex-start
    }

    .ceiqu {
        justify-content: space-between
    }

    .c66k4 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }

    .cm5ip {
        padding-top: 4rem;
        padding-bottom: 4rem
    }

    .c0ahj {
        padding-bottom: 4rem
    }

    .cm48u {
        padding-top: 9rem
    }

    .chjqk {
        text-align: left
    }
}

@media (min-width:1024px) {
    .cfh68 {
        margin-left: 5rem
    }

    .chrmv {
        display: block
    }

    .cb04d {
        display: flex
    }

    /* This is what was causing the apply button to appear only when hovering */

    /* .ct4vx {
        display: none
    } */

    .cqmv3 {
        width: 50%
    }

    .cy519 {
        width: 18rem
    }

    .cujho {
        width: auto
    }

    .c16gq {
        justify-content: flex-end
    }

    .c56h7>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px*var(--tw-space-x-reverse));
        margin-left: calc(0px*calc(1 - var(--tw-space-x-reverse)))
    }

    .c966h>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem*var(--tw-space-x-reverse));
        margin-left: calc(0.5rem*calc(1 - var(--tw-space-x-reverse)))
    }

    .clhjg>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px*calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px*var(--tw-space-y-reverse))
    }

    .c86o5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem
    }

    .cpiyw {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem
    }

    .c4tzn {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .always-visible {
        display: inline-block !important;
        opacity: 100 !important;
        visibility: visible !important;
    }

    .c6kou:hover .cjjz8 {
        display: block
    }

    .c6kou:hover .c2vtk {
        display: none
    }
}

.cmd8n:nth-child(-n+12) {
    order: -1
}